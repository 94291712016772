import React, { useState, useEffect } from "react"
import { SnackbarProvider } from "notistack"
import { useTranslation } from "react-i18next"
import axios from "axios"

import {
  Typography,
  Button,
  FormControl,
  TextField,
  makeStyles,
  Grid,
  Snackbar,
  ButtonGroup,
  CircularProgress,
} from "@material-ui/core"

import SEO from "../components/seo"
import MuiAlert from "@material-ui/lab/Alert"
import Breadcrumbs from "../components/breadcrumbs"

const windowGlobal = typeof window !== "undefined" && window
const documentGlobal = typeof document !== `undefined` && document

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />
}

const useStyles = makeStyles(theme => ({
  margin: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  mainContainer: {
    marginTop: "3.5rem",
    marginBottom: "3.5rem",
  },
  formConrolSignup: {
    padding: 0,
    "& .MuiOutlinedInput-root": {
      borderRadius: 10,
    },
    "& input": {
      padding: "10px 12px",
    },
    "& .MuiInputLabel-outlined": {
      transform: "translate(14px, 13px) scale(1)",
    },
    "& .MuiInputLabel-outlined.MuiInputLabel-shrink": {
      transform: "translate(14px, -6px) scale(0.75)",
    },
  },
  createAccountBtn: {
    height: 40,
    borderRadius: 10,
    fontWeight: "600",
  },
  heading: {
    textAlign: "center",
    marginBottom: "1rem",
    color: "#033f96",
    fontSize: "2.7rem",
  },
}))

const YourThoughts = ({ pageContext }) => {
  const classes = useStyles()
  const [t, i18n] = useTranslation()
  const emailRegExp = /^([A-Za-z0-9_\-.])+@([A-Za-z0-9_\-.])+\.([A-Za-z]{2,4})$/
  const phoneRegexd = /[0-9][0-9][0-9]|[0-9][0-9][0-9]|[0-9][0-9][0-9][0-9]$/

  const { pLang } = pageContext

  const [loading, setLoading] = useState(false)
  const [isSubmitted, setIsSubmitted] = useState(false)
  const [openAlertSuccess, setOpenAlertSuccess] = useState(false)
  const [openAlertError, setOpenAlertError] = useState(false)
  const [openAlertErrorOnSubmit, setOpenAlertErrorOnSubmit] = useState(false)
  const [disabledBtn, setDisabledBtn] = useState(false)
  const [errorMassegeCatch, setErrorMassegeCatch] = useState("")
  const [values, setValues] = useState({
    name: "",
    email: "",
    telWhats: "",
    message: "",
  })
  const [errorMassege, setErrorMassege] = useState({
    email: false,
    emailText: "",
    telWhats: false,
    telWhatsText: "",
  })

  useEffect(() => {
    i18n.on("languageChanged", () => {
      windowGlobal.history.pushState(
        pLang,
        "your-thoughts",
        `/${i18n.language}/your-thoughts`
      )
      if (windowGlobal.history.pushState) {
        windowGlobal.history.pushState(
          pLang,
          "your-thoughts",
          `/${i18n.language}/your-thoughts`
        )
      } else {
        documentGlobal.location.href = `/${i18n.language}/your-thoughts`
      }
    })
  }, [i18n, pLang])

  const breadcrumbs = [
    {
      text: t("yourThoughts.yourThoughtsTitle"),
    },
  ]

  const handleChange = prop => event => {
    if (prop === "email" && isSubmitted) {
      enterEmail()
    }
    setValues({ ...values, [prop]: event.target.value })
  }

  const enterEmail = () => {
    if (emailRegExp.test(values.email)) {
      setErrorMassege({ ...errorMassege, email: false, emailText: "" })
      return true
    } else {
      setErrorMassege({
        ...errorMassege,
        email: true,
        usernameText: "You have entered an invalid E-mail address!",
      })
      return false
    }
  }

  const enterdTelWhats = () => {
    if (phoneRegexd.test(values.telWhats)) {
      setErrorMassege({ ...errorMassege, telWhats: false, telWhatsText: "" })
      return true
    } else {
      setErrorMassege({
        ...errorMassege,
        telWhats: true,
        telWhatsText: "You have entered an invalid Phone number!",
      })
      return false
    }
  }

  const handleClose = reason => {
    if (reason === "clickaway") {
      return
    }
    setOpenAlertSuccess(false)
    setOpenAlertError(false)
    setOpenAlertErrorOnSubmit(false)
  }

  const handleSubmit = e => {
    e.preventDefault()
    setLoading(true)
    setIsSubmitted(true)

    if (enterdTelWhats()) {
      axios
        .post(`${process.env.GATSBY_API_URL}/contact-uses`, {
          name: values.name,
          email: values.email,
          phone: values.telWhats,
          message: values.message,
        })
        .then(() => {
          setOpenAlertSuccess(true)
          setDisabledBtn(true)
          setLoading(false)
          setTimeout(() => setOpenAlertSuccess(false), 2000)
        })
        .catch(err => {
          setLoading(false)
          setOpenAlertError(true)
          setDisabledBtn(true)
          setTimeout(() => setOpenAlertError(false), 2000)
          setErrorMassegeCatch(err.response.data.message)
        })
    } else {
      setLoading(false)
      setTimeout(() => setOpenAlertErrorOnSubmit(false), 2000)
      setOpenAlertErrorOnSubmit(true)
    }
  }

  return (
    <>
      <SEO title={t("yourThoughts.yourThoughtsTitle")} lang={i18n.language} />
      <Breadcrumbs links={breadcrumbs} />
      <Grid container className={classes.mainContainer}>
        <Grid item xs={1} md={3}></Grid>
        <Grid item xs={10} md={6}>
          <Typography variant="h3" component="h3" className={classes.heading}>
            {t("yourThoughts.yourThoughtsTitle")}
          </Typography>
          <form method="post" onSubmit={handleSubmit}>
            <FormControl
              fullWidth
              className={`${classes.margin} ${classes.formConrolSignup}`}
            >
              <TextField
                required
                id="name"
                type="text"
                label={t("contactUs.name")}
                variant="outlined"
                onChange={handleChange("name")}
              />
            </FormControl>
            <FormControl
              fullWidth
              className={`${classes.margin} ${classes.formConrolSignup}`}
            >
              <TextField
                id="email"
                type="email"
                label={t("contactUs.email")}
                variant="outlined"
                value={values.email}
                onChange={handleChange("email")}
              />
            </FormControl>
            <FormControl
              fullWidth
              className={`${classes.margin} ${classes.formConrolSignup}`}
            >
              <TextField
                required
                id="telwhats"
                type="text"
                label={t("contactUs.telWhats")}
                variant="outlined"
                value={values.telWhats}
                onChange={handleChange("telWhats")}
                error={errorMassege.telWhats}
                helperText={errorMassege.telWhatsText}
              />
            </FormControl>
            <FormControl
              fullWidth
              className={`${classes.margin} ${classes.formConrolSignup}`}
            >
              <TextField
                required
                id="message"
                type="textarea"
                multiline
                rows={3}
                rowsMax={8}
                label={t("contactUs.message")}
                variant="outlined"
                value={values.message}
                onChange={handleChange("message")}
              />
            </FormControl>
            <ButtonGroup
              fullWidth={true}
              color="primary"
              aria-label="Create account"
              disabled={disabledBtn}
              loading={loading.loading}
              className={`${classes.button} ${classes.margin} ${classes.buttonSignupSite} button`}
            >
              <Button
                type="submit"
                variant="contained"
                className={classes.createAccountBtn}
              >
                {loading && <CircularProgress size={26} />}
                {!loading && t("contactUs.submitBtn")}
              </Button>
            </ButtonGroup>
          </form>
        </Grid>
        <Grid item xs={1} md={3}></Grid>
      </Grid>
      <SnackbarProvider maxSnack={3}>
        <Snackbar
          open={openAlertSuccess}
          autoHideDuration={6000}
          onClose={handleClose}
        >
          <Alert onClose={handleClose} severity="success">
            Successfully signed up
          </Alert>
        </Snackbar>
        <Snackbar
          open={openAlertError}
          autoHideDuration={6000}
          onClose={handleClose}
        >
          <Alert onClose={handleClose} severity="error">
            {errorMassegeCatch}
          </Alert>
        </Snackbar>
        <Snackbar
          open={openAlertErrorOnSubmit}
          autoHideDuration={6000}
          onClose={handleClose}
        >
          <Alert onClose={handleClose} severity="error">
            please check all fields and try again!
          </Alert>
        </Snackbar>
      </SnackbarProvider>
    </>
  )
}

export default YourThoughts